// import Message from "../../pages/home/Message";
import "./closeFriend.css";
import { useState, useEffect } from "react";
// import Message from "./../../pages/messages/Message";
import { baseUrl } from "../../App";

export default function CloseFriend() {
  /****************** */
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [messages, setMessages] = useState([]);
  // const user = JSON.parse(localStorage.getItem("user"));
  const msg = JSON.parse(localStorage.getItem("msg"));

  const [formattedMessage, setFormattedMessage] = useState({});
  const user = JSON.parse(localStorage.getItem("user"));
  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const token = localStorage.getItem("token");

  const headers = new Headers({
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  });
  useEffect(() => {
    const newMessages = msg?.filter(
      (message) =>
        message.to === user.username || message.from === user.username
    );
    const otherUsers = newMessages?.map((message) =>
      message.to === user.username ? message.from : message.to
    );
    const uniqueOtherUsers = [...new Set(otherUsers)];

    const formattedMessages = uniqueOtherUsers.map((username) => {
      const messagesWithUser = newMessages.filter(
        (message) => message.to === username || message.from === username
      );
      const lastMessage =
        messagesWithUser[messagesWithUser.length - 1]?.content;
      const formattedMessage = {
        username,
        message: {
          content: lastMessage,
          createdAt: messagesWithUser[messagesWithUser.length - 1]?.createdAt,
        },
      };

      return formattedMessage;
    });

    setFormattedMessage(formattedMessages);
  }, [messages]);
  const formattedMessages = [
    /* your array of formattedMessage objects */
  ];

  formattedMessages.forEach((formattedMessage) => {
    console.log(formattedMessage.sort([0]?.message?.content));
  });
  formattedMessages.sort((a, b) => {
    const aCreatedAt = a.message.createdAt;
    const bCreatedAt = b.message.createdAt;
    if (aCreatedAt > bCreatedAt) {
      return -1;
    } else if (aCreatedAt < bCreatedAt) {
      return 1;
    }
    return 0;
  });
  // console.log(formattedMessage[0]?.username);
  // console.log(formattedMessage[0]?.message?.createdAt);
  // const { user } = useAuthState();
  useEffect(() => {
    // fetch list of users from backend

    fetch(`${baseUrl}/api/users`)
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (selectedUser) {
      // fetch messages for selected user from backend
      fetch(`${baseUrl}/api/messages/${selectedUser.username}`, headers)
        .then((response) => response.json())
        .then((data) => setMessages(data))
        .catch((error) => console.log(error));
    }
  }, [selectedUser]);
  return (
    <li className="sidebarFriend">
      <div className="users-list">
        <h5 className="mb-3">Friends</h5>
        {users.map((user) => (
          <div
            key={user.username}
            className={`user ${
              user.username === user.username ? "selected" : ""
            }`}
            onClick={() => setSelectedUser(user)}
            role="button"
          >
            <div className="user-info">
              <img
                className="sidebarFriendImg"
                src={
                  user.profilePicture
                    ? PF + user.profilePicture
                    : PF + "user.png"
                }
                alt=""
              />
              <div className="username">{user.username}</div>
              <div className="last-message">
                {user.username === formattedMessage[0]?.username ? (
                  <>
                    <span className="content">
                      {" "}
                      messages.text: {messages.text}
                      <span className="content">
                        {" "}
                        messages.content:{messages.content}::::
                      </span>
                      formattedMessage[0]?
                      {formattedMessage[0]?.message?.text}
                    </span>
                  </>
                ) : (
                  <span className="no-message">No messages yet</span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </li>
  );
}
