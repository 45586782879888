import Share from "../share/Share";
import Post from "../post/Post";
import "./feed.css";
import React from "react";
import axios from "axios";
import {  useEffect, useState } from "react";

import { baseUrl } from "../../App";

export default function Feed({ username }) {
  // const user = useAuthState();
  const [Posts, setPosts] = useState([]);
  // const user = localStorage.getItem("user");
  const token = localStorage.getItem("token");

  const headers = new Headers({
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  });
  useEffect(() => {
    const fetchPosts = async () => {
      axios
        .get(`${baseUrl}/api/posts/profile/ `+ username, {
          headers,
        })
        .then((response) => {
          setPosts(response.data);
          localStorage.setItem(
            " setLtOfPtsbyuserId",
            JSON.stringify(response.data)
          );
        });
    };
    fetchPosts();
  }, []);
  return (
    <div className="feed">
      <div className="feedWrapper">
        {<Share />}
        {Posts.map((p) => (
          <Post key={p.id} post={p} />
        ))}
      </div>
    </div>
  );
}
