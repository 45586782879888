
import React from "react";
import "./rightbar.css";

import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {  baseUrl } from "../../App";
import { Add, Remove } from "@material-ui/icons";
import ChatOnline from "../chatOnline/ChatOnline";
import { useAuthState } from "../../context/auth";
import CloseFriend from "../closeFriend/CloseFriend";

import SidePost from "../sidePost/SidePost";

export default function Rightbar() {

  const { user } = useAuthState();
  const followings = user;
  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const [friends, setFriends] = useState([]);
  const { user: currentUser, dispatch } = useAuthState;
  const users = JSON.parse(localStorage.getItem("userAount"));
  const token = localStorage.getItem("token");
  const posts = JSON.parse(localStorage.getItem(" setLtOfPtsbyuserId"));
  const headers = new Headers({
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  });
  const [followed, setFollowed] = useState(
    users?.includes(user?.id)
  );

  useEffect(() => {
    const getFriends = async () => {
      try {
        const friendList = await axios.get(
          `${baseUrl}/api/users/friends/` + user?.id,
          headers
        );
        setFriends(friendList.data);
      } catch (err) {
        console.log(err);
      }
    };
    getFriends();
  }, []);

  const handleClick = async () => {
    try {
      if (followed) {
        await axios.put(
          `${baseUrl}/api/users/${user.id}/unfollow`,
          {
            userId: user.id,
          },
          headers
        );
        dispatch({ type: "UNFOLLOW", payload: user.id });
      } else {
        await axios.put(
          `${baseUrl}/api/users/${user.id}/follow`,
          {
            userId: user.id,
          },
          headers
        );
        dispatch({ type: "FOLLOW", payload: user.id });
      }
      setFollowed(!followed);
    } catch (err) {}
  };

  const HomeRightbar = () => {
    return (
      <>
        <div className="birthdayContainer">
          <img className="birthdayImg" src="assets/gift.png" alt="" />
          <span className="birthdayText">
            <b>Pola Foster</b> and <b>3 other friends</b> have a birhday today.
          </span>
        </div>
        <img className="rightbarAd" src="assets/ad.png" alt="" />
        <h4 className="rightbarTitle">Online Friends</h4>
        <ul className="rightbarFriendList">
          {users?.map((u) => (
            <ChatOnline key={u.id} user={u} />
          ))}
        </ul>
      </>
    );
  };

  const ProfileRightbar = () => {
    return (
      <>
        <ul className="sidebarFriendList">
          <CloseFriend />
        </ul>
        {/* user.username !== currentUser.username && */}{" "}
        {
          <button className="rightbarFollowButton" onClick={handleClick}>
            {followed ? "Unfollow" : "Follow"}
            {followed ? <Remove /> : <Add />}
          </button>
        }
        <h4 className="rightbarTitle">User information</h4>
        <div className="rightbarInfo">
          <div className="rightbarInfoItem">
            <span className="rightbarInfoKey">City:</span>
            <span className="rightbarInfoValue">{user?.username}</span>
          </div>
          <div className="rightbarInfoItem">
            <span className="rightbarInfoKey">From:</span>
            <span className="rightbarInfoValue">{user?.username}</span>
          </div>
          <div className="rightbarInfoItem">
            <span className="rightbarInfoKey">Relationship:</span>
            <span className="rightbarInfoValue">
              {/* {user.relationship === 1
                ? "Single"
                : user.relationship === 1
                ? "Married"
                : "-"} */}
            </span>
          </div>
        </div>
        <h4 className="rightbarTitle">User friends</h4>
        <div className="rightbarFollowings">
          {friends.map((friend) => (
            <Link
              key={friend.id}
              to={"/profile/" + friend.username}
              style={{ textDecoration: "none" }}
            >
              <div className="rightbarFollowing">
                <img
                  src={
                    friend.profilePicture
                      ? `${baseUrl}/images/` + friend.profilePicture
                      : `${baseUrl}/images/` + "noAvatar.png"
                  }
                  alt=""
                  className="rightbarFollowingImg"
                />
                <span className="rightbarFollowingName">{friend.username}</span>
              </div>
            </Link>
          ))}
        </div>
      </>
    );
  };
  return (
    <div className="right">
      <div className="rightbar">
        <div className="rightbarWrapper">
           <ProfileRightbar />
        <HomeRightbar />
          {posts?.map((post) => (
            <SidePost key={post?.id} post={post} />
          ))}
        </div>
      </div>
    </div>
  );
}
