// import React from "react";
import "./share.css";
import { PermMedia, Cancel } from "@material-ui/icons";
import { useRef, useState } from "react";
import axios from "axios";
import { useAuthState } from "../../context/auth";
import { baseUrl } from "../../App";
// const url = `${baseUrl}/images`;
export default function Share() {
  const  user  = useAuthState();
  console.log({user})

  const desc = useRef();
  // const title = useRef();
  const [file, setFile] = useState(null);
  const submitHandler = async (e) => {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
    const newPost = {
      userId: user.id,
      desc: desc.current.value,
      // title: title.current.value,
    };
    if (file) {
      const data = new FormData();
      const fileName = Date.now() + file.name;
      data.append("name", fileName);
      data.append("file", file);
      newPost.img = fileName;
      console.log(newPost);
      try {
        await axios.post(`${baseUrl}/upload`, data);
      } catch (err) {}
    }
    try {
      await axios.post(`${baseUrl}/api/posts`, newPost, {
        headers,
      });
      window.location.reload();
    } catch (err) {}
  };

  const handleVideoUpload = (e) => {
    const video = e.target.files[0];
    setFile(video);
  };

  return (
    <div className="share">
      <div className="shareWrapper">
        <div className="shareTop">
          <img
            className="shareProfileImg"
            src={
              user.profilePicture
                ? `${baseUrl}/images/` + user.profilePicture
                : `${baseUrl}/images/` + "noAvatar.png"
            }
            alt="profilePicture"
          />

          {/* <input
            placeholder={user.username + ": Create Post : Title ?"}
            className="shareInput"
            ref={title}
          /> */}
          <input
            placeholder={"Description ?"}
            className="shareInput"
            ref={desc}
          />
        </div>
        <hr className="shareHr" />
        {file && (
          <div className="shareImgContainer">
            {file.type.startsWith("video/") ? (
              <video className="shareVideo" controls>
                <source src={URL.createObjectURL(file)} type={file.type} />
              </video>
            ) : (
              <img
                className="shareImg"
                src={URL.createObjectURL(file)}
                alt=""
              />
            )}
            <Cancel className="shareCancelImg" onClick={() => setFile(null)} />
          </div>
        )}
        <form className="shareBottom" onSubmit={submitHandler}>
          <div className="shareOptions">
            <label htmlFor="file" className="shareOption">
              <PermMedia htmlColor="tomato" className="shareIcon" />
              <span className="shareOptionText">Multimedia</span>
              <input
                style={{ display: "none" }}
                type="file"
                id="file"
                accept=".png,.jpeg,.jpg,.mp4"
                onChange={handleVideoUpload}
              />
            </label>
          </div>
          <button className="shareButton" type="submit">
            Share
          </button>
        </form>
      </div>
    </div>
  );
}
