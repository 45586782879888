
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Chat from '@material-ui/icons/Chat';
import { useAuthState } from '../../context/auth';
import { baseUrl } from '../../App';
import MenuBurger from "../menuBurger/MenuBurger";

import "./topbar.css"
function Topbar({ users, messages }) {
  const navigate = useNavigate();
  
  const isAuthenticated = useAuthState();
  const user = JSON.parse(localStorage.getItem('user'));

  const handleAdminClick = () => {
    // Implement what should happen when the Admin icon is clicked
    // For example, navigate to the admin dashboard
    navigate('/admin');
  };

  const handleMessagesClick = () => {
    // Implement what should happen when the Messages icon is clicked
    // For example, navigate to the Messages page
    navigate('/messages');
  };

  const handleChatClick = () => {
    // Implement what should happen when the Chat icon is clicked
    // For example, navigate to the Chat page
    navigate('/chat');
  };

  const handleMessengerClick = () => {
    // Implement what should happen when the Messenger icon is clicked
    // For example, navigate to the Messenger page
    navigate('/messenger');
  };

  const handleProfileClick = () => {
    // Implement what should happen when the profile picture is clicked
    // For example, navigate to the user's profile page
    navigate(`/profile/${user.username}`);
  };

  const messageCount = messages?.length;
  const usersCount = users?.length;
  const url = `${baseUrl}/images/`;

  return (
    <div className="topbarContainer">
      {user && (
        <div className="topbarLeft">
          <span
            className="logo"
            onClick={() => {
              navigate('/');
            }}
          >
            Kifach
          </span>
        </div>
      )}
      <div className="top-bar">{/* Add your message and user count */}</div>
      {user && (
        <div className="topbarRight">
          <div className="topbarLinks">
            <div className="topbarIconItem">
              <SupervisorAccountIcon onClick={handleAdminClick} />
              <span className="topbarIconBadge">{usersCount}</span>
            </div>
          </div>
          <span className="logo" onClick={handleMessagesClick}>
            Ms
          </span>
          <span className="logo" onClick={handleChatClick}>
            Cht
          </span>
          <div className="topbarIcons">
            <div className="topbarIconItem"></div>
            <div className="topbarIconItem">
              <Chat onClick={handleMessengerClick} />
              <span className="topbarIconBadge">{messageCount}</span>
            </div>
          </div>
          <img
            src={user.profilePicture ? url + user.profilePicture : url + 'noAvatar.png'}
            alt=""
            className="topbarImg"
            onClick={handleProfileClick}
          />
          <span className="topbarLinks">{user.username} </span>
                       <div className="topbarIconItem">
             <MenuBurger />
             </div>
        </div>
      )}
    </div>
  );
}

export default Topbar;
