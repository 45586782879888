  // eslint-disable-next-line

import React, { useState } from "react";

import { baseUrl } from '../../App';
import "./register.css"
import axios from "axios";
import { useRef } from "react";
import { useNavigate  } from "react-router-dom";
// import CheckBox from './../login/CheckBox';
export default function Register() {
  const username = useRef();
  const email = useRef();
  const password = useRef();
  const passwordAgain = useRef();
  const navigate  = useNavigate ();
  const handleClick = async (e) => {
    e.preventDefault();
    if (passwordAgain.current.value !== password.current.value) {
      passwordAgain.current.setCustomValidity("Passwords don't match!");
    } else {
      const user = {
        username: username.current.value,
        email: email.current.value,
        password: password.current.value,
        password: password.current.value,
      };
      try {
        await axios.post(`${baseUrl}/api/register`, user);
        navigate("/login");
      } catch (err) {
        console.log(err);
        window.location.reload();
      }
    }
  };
  return (
    <div className="login-container">
      <div className="cardlogin">
        <div className="container">

          <form onSubmit={handleClick}>
            <h1>Register</h1>
            <label htmlFor="Username">
              Username
              <input
                type="username"
                minLength="3"
                placeholder="Username"
                autoComplete="off"
                required
                ref={username}
                className="loginInput"
              />
            </label>
            <label htmlFor="email">
              Email Address
              <input
                placeholder="Email"
                required
                ref={email}
                className="loginInput"
                type="email"
                autoComplete="off"
              />
            </label>
            <label htmlFor="password">
              Password
              <input
                placeholder="Password"
                required
                ref={password}
                className="loginInput"
                type="password"
                minLength="6"
              />
            </label>
            <label htmlFor="Password Again">
              Password Again
              <input
                placeholder="Password Again"
                required
                ref={passwordAgain}
                className="loginInput"
                type="password"
              />
            </label>
            <button className="btn btn-outline-success  btn-sm" type="submit">
              Sign Up
            </button>
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </button>
            {/* <div><CheckBox/></div> */}
          </form>
        </div>
      </div>
    </div>
  );
}

// import "./register.css"
// import { useNavigate  } from 'react-router-dom';
// export default function Register(props) {
//   const navigate  = useNavigate ();
//   const [variables, setVariables] = useState({
//     email: "",
//     username: "",
//     password: "",
//     confirmPassword: "",
//   });

//   const [errors, setErrors] = useState({});

//   const registerUser = () => {

//     // make a network request to the server to register the user
//     fetch(`${baseUrl}/api/register`, {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify(variables),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.errors) {
//           // if there are errors, set them in state
//           setErrors(data.errors);
//         } else {
//           // if registration is successful, redirect to login page
//           props.navigate("/login");
//         }
//       })
//       .catch((error) => console.log(error));
//   };

//   const submitRegisterForm = (e) => {
//     e.preventDefault();
//     registerUser();
//   };

//   return (
//     // <Row className="bg-white py-5 justify-content-center">

//     <div>
//       <div className="login-container">
//         <div className="cardlogin">
//           <div className="loginRight">
//             <form onSubmit={submitRegisterForm}>
//               <h1>ChatBubble
//               </h1>
//               <label htmlFor="Username">
//                 Username
//                 <input
//                   type="text"
//                   value={variables.username}
//                   className={errors.username && "is-invalid"}
//                   onChange={(e) =>
//                     setVariables({ ...variables, username: e.target.value })
//                   }
//                 />
//               </label>
//               <label htmlFor="email">
//                 Email Address
//                 <input
//                   type="email"
//                   value={variables.email}
//                   className={errors.email && "is-invalid"}
//                   onChange={(e) =>
//                     setVariables({ ...variables, email: e.target.value })
//                   }
//                 />
//               </label>
//               <label htmlFor="password">
//                 Password
//                 <input
//                   type="password"
//                   value={variables.password}
//                   className={errors.password && "is-invalid"}
//                   onChange={(e) =>
//                     setVariables({ ...variables, password: e.target.value })
//                   }
//                 />
//               </label>
//               <label htmlFor="Password Again">
//                 Password Again
//                 <input
//                   type="password"
//                   value={variables.confirmPassword}
//                   className={errors.confirmPassword && "is-invalid"}
//                   onChange={(e) =>
//                     setVariables({
//                       confirmPassword: e.target.value,
//                     })
//                   }
//                 />
//               </label>
//               <button className="loginRegisterButton" type="submit">
//                 Sign Up
//               </button>
//               <button
//                 className="btn btn-outline-danger btn-sm"
//                 onClick={() => {
//                   navigate("/login");
//                 }}
//               >
//                 Login
//               </button>
//               {/* <div><CheckBox/></div> */}
//             </form>
//           </div>
//         </div>
//       </div>

//     </div>
//   );
// }