
import React, { useState, useEffect, useRef, Fragment } from "react";
import { ListGroup, Badge, Spinner, Alert, Form, Col } from "react-bootstrap";
import { io } from "socket.io-client";
import axios from "axios";
import { useAuthState } from "../../context/auth";
import { useMessageDispatch, useMessageState } from "../../context/message";
import Message from "./Message";
import notificationSound from "./../../assets/notif.wav";
import { baseUrl } from "../../App";

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const messageDispatch = useMessageDispatch();
  // const { messages } = useMessageState();
  const [users, setUsers] = useState([]);

  const { user } = useAuthState();
  const token = (localStorage.getItem("token"));

  // const baseUrl = "http://localhost:4000";

  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const socketRef = useRef();

  useEffect(() => {
    socketRef.current = io(baseUrl, {
      auth: {
        token: `Bearer ${token}`,
      },
    });

    socketRef.current.on("connect", () => {
      console.log("Connected to socket server");
    });

    socketRef.current.on("messageReceived", (message) => {
      messageDispatch({ type: "ADD_MESSAGE", payload: message });

      // Play notification sound
      const audio = new Audio(notificationSound);
      audio.play();
    });

    socketRef.current.on("error", (error) => {
      console.error("Socket error:", error);
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [messageDispatch, token]);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/api/users`, {
          headers,
        });
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleSelectUser = (user) => {
    setSelectedUser(user);
  };

  const [content, setContent] = useState("");
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);

    if (content.trim() === "") {
      setError(true);
      return;
    }

    const message = {
      from: user.id,
      to: selectedUser.id,
      content: content.trim(),
    };
    const res = await axios.post(`${baseUrl}/api/messages`, message);
    setMessages((prevMessages) => [...prevMessages, { message: res.data.content, id: res.data.id }])
    socketRef.current.emit("sendMessage", message);

    messageDispatch({ type: "ADD_MESSAGE", payload: message });
    setContent("");
  };

  // const formattedMessages = messages?.filter(
  //   (message) =>
  //     (message.from === user.id && message.to === selectedUser?.id) ||
  //     (message.from === selectedUser?.id && message.to === user.id)
  // );
  const [formattedMessages, setFormattedMessages] = useState([]);
  const [formattedMessage, setFormattedMessage] = useState({});
  useEffect(() => {
    const newMessages = messages.filter(
      (message) =>
        message.to === user.username || message.from === user.username
    );
    const otherUsers = newMessages.map((message) =>
      message.to === user.username ? message.from : message.to
    );
    const uniqueOtherUsers = [...new Set(otherUsers)];

    const formattedMessages = uniqueOtherUsers.map((username) => {
      const messagesWithUser = newMessages.filter(
        (message) => message.to === username || message.from === username
      );
      const lastMessage =
        messagesWithUser[messagesWithUser.length - 1]?.content;
      const formattedMessage = {
        username,
        message: {
          content: lastMessage,
          createdAt: messagesWithUser[messagesWithUser.length - 1]?.createdAt,
        },
      };

      return formattedMessage;
    });

    setFormattedMessages(formattedMessages);
  }, [user, messages]);

  formattedMessages.forEach((formattedMessage) => {
    console.log(formattedMessage?.message.content);
  });
  useEffect(() => {
    if (selectedUser) {
      axios
        .get(`${baseUrl}/api/messages/${selectedUser?.username}`, { headers })
        .then((response) => {
          setMessages(response.data);

        })
        .catch((error) => console.log(error));
    }
  }, [selectedUser]);


  return (
    <div className="d-flex">
      <div style={{ width: "250px" }}>

        < h4 > Users 2</h4>
        {loading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <ListGroup>
            {users.map((user) => (
              <ListGroup.Item
                key={user.id}
                action
                onClick={() => handleSelectUser(user)}
                active={selectedUser?.id === user.id}
              >
                <Badge
                  bg={
                    selectedUser?.username === user.username
                      ? user.online
                        ? "primary"
                        : "secondary"
                      : user.unread
                        ? "danger"
                        : user.online
                          ? "primary"
                          : "secondary"
                  }
                >
                  {selectedUser?.username === user.username
                    ? user.online
                      ? "Online"
                      : "Offline"
                    : user.unread
                      ? "New"
                      : user.online
                        ? "Online"
                        : "Offline"}
                </Badge>
                {user.username}
                {formattedMessages[0]?.username === user.username ? (
                  <Badge pill variant="danger" className="ml-2">
                    {formattedMessages[0]?.message.content}
                  </Badge>
                ) : (<Badge>Select a un Esclave </Badge>)}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </div>
      <div className="flex-grow-1">
        {selectedUser ? (
          <div className="d-flex flex-column h-100">
            <div className="flex-grow-1 overflow-auto">
              {messages?.map((message, index) => (
                <Fragment key={index}>
                  <Message message={message} />
                  {index === formattedMessages.length - 1 && loading && (
                    <Spinner
                      animation="border"
                      variant="primary"
                      style={{ marginLeft: "50%", marginTop: "1rem" }}
                    />
                  )}
                </Fragment>
              ))}
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Col>
                  <Form.Control
                    type="text"
                    className="rounded-pill"
                    placeholder="Enter your message..."
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    isInvalid={error}
                  />
                </Col>
                <Col xs="auto">
                  <button className="btn btn-primary rounded-pill" type="submit">
                    Send
                  </button>
                  <i
                    className="fas fa-paper-plane fa-2x text-primary mx-2"
                    // onClick={submitMessage}
                    role="button"
                  ></i>
                </Col>
              </Form.Row>
            </Form>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-center">
              <Alert variant="info">Select a user to start messaging</Alert>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Messages;
