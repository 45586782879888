import axios from "axios";
import { useEffect, useState } from "react";
import "./chatOnline.css";
import { baseUrl } from "../../App";
export default function ChatOnline({ onlineUsers }) {
  const [friends, setFriends] = useState([]);
  const [currentChat, setCurrentChat] = useState("");
  const [onlineFriends, setOnlineFriends] = useState([]);
  const PF = `${baseUrl}/images/`;
  const user = JSON.parse(localStorage.getItem("user"));
  const USERS = JSON.parse(localStorage.getItem("users"));

  const token = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  useEffect(() => {
    const getFriends = async () => {
      const res = await axios.get(`${baseUrl}/api/users`);
      setFriends(res.data);
      // localStorage.setItem("USERS", JSON.stringify(res.data));
    };

    getFriends();
  }, [user?.id]);

  useEffect(() => {
    setOnlineFriends(friends, onlineUsers);
  }, [friends, onlineUsers]);

  const handleClick = async () => {
    try {
      const res = await axios.get(`${baseUrl}/api/messages/${user.username}`, {
        headers,
      });
      setCurrentChat(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  //onlineUsers
  return (
    <div className="chatOnline">
      {USERS?.map((o) => (
        <div className="chatOnlineFriend" onClick={() => handleClick(user)}>
          <div className="chatOnlineImgContainer">
            <img
              className="chatOnlineImg"
              src={o?.profilePicture ? PF + o.profilePicture : PF + "user.png"}
              alt=""
            />
            <div
              className={`chatOnlineBadge ${o.online ? "online" : "offline"}`}
            ></div>
          </div>
          <span className="chatOnlineName">{user.username}</span>
        </div>
      ))}
    </div>
  );
}

// export default function ChatOnline({ onlineUsers, currentId, setCurrentChat }) {
//   const [friends, setFriends] = useState([]);
//   const [onlineFriends, setOnlineFriends] = useState([]);
//   const PF = process.env.REACT_APP_PUBLIC_FOLDER;

//   useEffect(() => {
//     const getFriends = async () => {
//       const res = await axios.get(
//         "http://localhost:4000/api/users/friends/" + currentId
//       );
//       setFriends(res.data);
//     };

//     getFriends();
//   }, [currentId]);

//   useEffect(() => {
//     setOnlineFriends(friends.filter((f) => onlineUsers.includes(f.id)));
//   }, [friends, onlineUsers]);

//   const handleClick = async (user) => {
//     try {
//       const res = await axios.get(
//         `http://localhost:4000/api/conversations/find/${currentId}/${user.id}`
//       );
//       setCurrentChat(res.data);
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   return (
//     <div className="chatOnline">
//       {onlineFriends.map((o) => (
//         <div className="chatOnlineFriend" onClick={() => handleClick(o)}>
//           <div className="chatOnlineImgContainer">
//             <img
//               className="chatOnlineImg"
//               src={
//                 o?.profilePicture
//                   ? PF + o.profilePicture
//                   : PF + "personnoAvatar.png"
//               }
//               alt=""
//             />
//             <div className="chatOnlineBadge">2b</div>
//           </div>
//           <span className="chatOnlineName">o.username</span>
//         </div>
//       ))}
//     </div>
//   );
// }
