import React from "react";
import { useRoutes, Outlet, useNavigate,Route } from "react-router-dom";
import { useAuthState } from "./context/auth";
import Home from "./pages/Home/home";
import Login from "./pages/login/login";
import Register from './pages/register/register';
import Messenger from "./pages/messenger/Messenger";
import NotFound from './NotFound';
import Messages from './pages/messages/Messages';

function PrivateRoute({ path, element }) {
  const { user } = useAuthState();
  const navigate = useNavigate();

  if (!user) {
    // If the user is not authenticated, redirect to the login page
    navigate("/login");
    return null;
  }

  return <Route path={path} element={element} />;
}

function PublicRoute({ path, element }) {
  const { user } = useAuthState();
  const navigate = useNavigate();

  if (user) {
    // If the user is authenticated, redirect to the home page
    navigate("/");
    return null;
  }

  return <Route path={path} element={element} />;
}

export default function AppRoutes() {
  const routes = useRoutes([
    {
      path: "/",
      element: <Outlet />,
      children: [
        { path: "/", element: <Home /> },
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "messenger", element: <Messenger /> },
        { path: "messages", element: <Messages /> },
      ],
    },
    { path: "/*", element: <NotFound /> }, // Handle 404 Not Found
  ]);

  return routes;
}
