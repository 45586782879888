// import React, { useState, useEffect, useRef } from "react";
// import {
//   Row,
//   Col,
//   ListGroup,
//   Badge,
//   Form,
//   Button,
  
// } from "react-bootstrap";


// // import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

// import Keyboard from "react-simple-keyboard";
// import "react-simple-keyboard/build/css/index.css";

// import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
// import { BrowserRouter, useNavigate , Link } from "react-router-dom";
// import { io } from "socket.io-client";
// import { useMessageDispatch } from "../../context/message";
// import axios from "axios";
// import { useAuthState } from "../../context/auth";
// import { baseUrl } from "../../App";
// // import "./messenger.css";
// import styled from "styled-components";
// import classNames from "classnames";
// import data from '@emoji-mart/data'
// // import Picker from '@emoji-mart/react'
// import SpeechRecognition, {
//   useSpeechRecognition
// } from "react-speech-recognition";
// import "./messenger.css"
// import Geolocation from "react-geolocation";
// import Topbar from "../../components/topbar/Topbar";
import React, { useState, useEffect, useRef } from "react";
import { ListGroup, Badge, Form, Button } from "react-bootstrap";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { useMessageDispatch } from "../../context/message";
import axios from "axios";
import { useAuthState } from "../../context/auth";
import { baseUrl } from "../../App";
import styled from "styled-components";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import "./messenger.css";
import Geolocation from "react-geolocation";
import Topbar from "../../components/topbar/Topbar";
export const MessengerContainer = styled.div`
  display: flex;
  height: 100vh;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const UserListContainer = styled.div`
  flex: 1;
  background-color: #f2f2f2;
  padding: 20px;
  overflow-y: auto;
  @media (max-width: 768px) {
    flex: none;
    height: 40vh;
    overflow-y: auto;
  }
`;

export const MessageSectionContainer = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  @media (max-width: 768px) {
    flex: none;
    height: 60vh;
  }
`;
const Emoticon = styled.span`
  &::before {
    content: 'ðŸ˜Š'; /* You can replace this with your desired emoji */
    margin-right: 5px; /* Adjust spacing as needed */
  }
`;
export const UserListItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  background: linear-gradient( to right, #0A7CFF, hsl(212, 100%, 67%) );
  border-radius: 5px;
  border: 3px solid #0A7CFF;
  /* color:#ffffff; */
  font-size: 0.8rem; 
  cursor: pointer;
  padding: 0.25em 0.75em;
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  transition: all 0.4s ease-in-out;
  box-shadow: 0px -1px 5px -3px rgb(106, 101, 145);
  -webkit-box-shadow: 0px -1px 36px -3px rgb(106, 101, 145);
  -moz-box-shadow: 0px -1px 36px -3px rgb(106, 101, 145);

  &:hover {
    background: -webkit-linear-gradient(
    45deg,
    rgb(133, 29, 186) 15%,
    rgba(255, 0, 224, 1) 100%
  );
  }
`;

export const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const Username = styled.span`
  font-weight: bold;
  
`;

export const LastMessage = styled.span`
  font-size: 12px;
  color: #777;
`;

export const MessageHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
`;

export const MessageAvatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const OnlineStatus = styled.span`
  font-size: 12px;
  color: #777;
`;

export const OfflineStatus = styled.span`
  font-size: 12px;
  color: #777;
`;

export const MessageBody = styled.div`

  flex: 1;
  overflow-y: auto;
  padding: 10px 20px 0 0;
`;

 export const Message = styled.div`

  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.outgoing === "true" ? "flex-end" : "flex-start"};
  margin-bottom: 10px;
`;

export const MessageContent = styled.div`
  background: -webkit-linear-gradient(
    45deg,
    rgb(133, 29, 186) 15%,
    rgba(255, 0, 224, 1) 100%
  );

  padding: 10px;
  border-radius: 5px;
`;

export const StyledSpan = styled.span`
  background-color: pink;
  padding: 5px;
  border-radius: 5px;
  color: #333;
`;

export const MessageTime = styled.span`
  font-size: 12px;
  color: #777;
`;


export const MessageFooter = styled.div`
  padding-top: 10px;


`;
export const MessageButton = styled.button`
  padding-top: 10px;
   &:hover {
  transform: scale(1.2);
}
`;
export const FormGroup = styled.div`
  background-color: rgb(235, 235, 235);
  border: 3px solid #d0d0d0;
  /* width: 60%; */
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  

`;

export const EmptyMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #777;
  font-size: 18px;
`;

export const Alert = styled.div`
  margin-bottom: 10px;
`;
 export const wrapperForm = styled.div`
display: flex !important;
background-color: aqua;
`;


const Messenger = () => {
  // // const { user } = useAuthState();
  // const user = JSON.parse(localStorage.getItem("user"));
  // const socket = useRef(null);
  // const [users, setUsers] = useState([]);
  // const [messages, setMessages] = useState([]);
  // const [content, setContent] = useState("");
  // const [selectedUser, setSelectedUser] = useState(null);
  // const [isOnline, setIsOnline] = useState(false);
  // const [onlineUsers, setOnlineUsers] = useState([]);
  // const scrollRef = useRef(null);
  // const [error, setError] = useState("");
  // const PF = `${baseUrl}/images/`;
  // const messageDispatch = useMessageDispatch();
  // const [userStatus, setUserStatus] = useState({});
  // const { finalTranscript, resetTranscript } = useSpeechRecognition();
  // const [showKeyboard, setshowKeybord] = useState(false);
  // const [layout, setLayout] = useState("default");
  // const [showAlert, setShowAlert] = useState(false);
  // const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  // const [status, setStatus] = useState(false);
  // const keyboard = useRef();
  // const messagesEndRef = useRef(null);
  // const [scrollTop, setScrollTop] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const socket = useRef(null);
  const [users, setUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [content, setContent] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const scrollRef = useRef(null);
  const [error, setError] = useState("");
  const PF = `${baseUrl}/images/`;
  const messageDispatch = useMessageDispatch();
  const { finalTranscript, resetTranscript } = useSpeechRecognition();
  const [showKeyboard, setshowKeybord] = useState(false);
  const [layout, setLayout] = useState("default");
  const [showAlert, setShowAlert] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [status, setStatus] = useState(false);
  const keyboard = useRef();
  const messagesEndRef = useRef(null);
  const [scrollTop, setScrollTop] = useState(false);

  // const socketRef = useRef();

  // useEffect(() => {
  //   socketRef.current = io(baseUrl, {
  //     auth: {
  //       token: `Bearer ${token}`,
  //     },
  //   });

  //   socketRef.current.on("connect", () => {
  //     console.log("Connected to socket server");
  //   });

  //   socketRef.current.on("messageReceived", (message) => {
  //     messageDispatch({ type: "ADD_MESSAGE", payload: message });

  //     // Play notification sound
  //     // const audio = new Audio(notificationSound);
  //     // audio.play();
  //   });

  //   socketRef.current.on("error", (error) => {
  //     console.error("Socket error:", error);
  //   });

  //   return () => {
  //     socketRef.current.disconnect();
  //   };
  // }, [messageDispatch, token]);

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
     const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };
  useEffect(() => {
    if (scrollTop) return setScrollTop(false);
    scrollToBottom();
  }, [messages]);
  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };
  const onKeyPress = (button) => {
    console.log("Button pressed", button);
    //If you want to handle the shift and caps lock buttons
    if (button === "{shift}" || button === "{lock}") handleShift();
  };
  const onChange = (content) => {
    setContent(content);
    console.log("Input changed", content);
  };
  const keyboardToggle = (e) => {
    console.log("in keyboardToggle");
    if (showKeyboard === true) {
      setshowKeybord(false);
      console.log("picker not visible");
    } else {
      setshowKeybord(true);
      console.log("picker visible");
    }
  };
  
  useEffect(() => {
    if (finalTranscript !== "") {
      setShowAlert(false);
      // setInput(finalTranscript);
       setContent(finalTranscript);

      
      resetTranscript();
    }
  });
  const Speechtoinput = (e) => {
    setShowAlert(true);
    SpeechRecognition.startListening();
  };
  const handleEmojiSelect = (emoji) => {
    setContent(content + emoji.native);
  };

  const token = JSON.parse(localStorage.getItem("token"));
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const [formattedMessages, setFormattedMessages] = useState([]);
  const [formattedMessage, setFormattedMessage] = useState({});
  
  useEffect(() => {
    const newMessages = messages.filter(
      (message) =>
        message.to === user.username || message.from === user.username
    );
    const otherUsers = newMessages.map((message) =>
      message.to === user.username ? message.from : message.to
    );
    const uniqueOtherUsers = [...new Set(otherUsers)];

    const formattedMessages = uniqueOtherUsers.map((username) => {
      const messagesWithUser = newMessages.filter(
        (message) => message.to === username || message.from === username
      );
      const lastMessage =
        messagesWithUser[messagesWithUser.length - 1]?.content;
      const formattedMessage = {
        username,
        message: {
          content: lastMessage,
          createdAt: messagesWithUser[messagesWithUser.length - 1]?.createdAt,
        },
      };

      return formattedMessage;
    });

    setFormattedMessages(formattedMessages);
  }, [messages]);

  formattedMessages.forEach((formattedMessage) => {
    console.log(formattedMessage?.message.content);
  });
  console.log(users.length);
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/users/`, { headers });
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUsers();

    socket.current = io(baseUrl);

    socket.current.on("connect", () => {
      console.log("Connected to socket");
      socket.current.emit("addUser", user.id);
      
    });
    socket.current.on("getUsers", (users) => {
      setOnlineUsers(users);
    });
    
    socket.current.on("getMessage", (message) => {
      console.log("Received Message:", message);
      setMessages((prevMessages) => [...prevMessages, message]);
      messageDispatch({ type: "ADD_MESSAGE", payload: message });

    });
    

    socket.current.on("disconnect", () => {
      console.log("Disconnected from socket");
      setOnlineUsers([]);
    });

    return () => {
      socket.current.disconnect();
    };
  }, []);
  /**admin***/
  const updateOnlineUsers = (users) => {
    const updatedUsers = users.map((user) => ({
      ...user,
      online: socket.current.connected && user.socketId === socket.current.id,
    }));
    setOnlineUsers(updatedUsers);
  };

  // Inside the component
  useEffect(() => {
    const handleGetUsers = (users) => {
      updateOnlineUsers(users);
    };

    // socket.current.emit("addUser", user.id);
    socket.current.on("getUsers", handleGetUsers);

    // Cleanup the event listener when the component unmounts
    return () => {
      socket.current.off("getUsers", handleGetUsers);
    };
  }, []);

  useEffect(() => {
    if (selectedUser) {
      fetch(`${baseUrl}/api/messages/${selectedUser.username}`, { headers })
        .then((response) => response.json())
        .then((data) => setMessages(data))
        .catch((error) => console.log(error));
    }
  }, [selectedUser]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [messages]);

  const submitMessage = async (e) => {
    e.preventDefault();

    if (!selectedUser) {
      setError("Recipient is required");
      return;
    }

    if (content.trim() === "") {
      setError("Message content is required");
      return;
    }

    try {
      const message = {
        to: selectedUser.username,
        from: user.username,
        content,
      };
      const res = await axios.post(`${baseUrl}/api/messages`, message);

   const newMessage = {
     to: res.data.to,
     from: res.data.from,
     content: res.data.content,
   };

      socket.current.emit("sendMessage", newMessage);
      setContent("");
      setError("");

      setMessages((prevMessages) => [...prevMessages, newMessage]);
      scrollRef.current.scrollBy({
        top: scrollRef.current.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      console.log(error);
      setError("Failed to send message");
    }
  };

   console.log(Message);
  const latestMessage = formattedMessages[formattedMessages.length - 1];
  useEffect(() => {
    // Subscribe to the 'onlineUsers' event emitted by the server
    socket.current.on("user_status", (users) => {
      setOnlineUsers(users);
    });

    // Cleanup the socket subscription on unmount
    return () => {
      socket.current.off("user_status");
    };
  }, []);
  const UserList = ({ users, onlineUsers }) => {
    return (
      <ListGroup>
        {users.map((user) => (
          <UserListItem
            key={user.id}
            // action="true"
            onClick={() => setSelectedUser(user)}
            active={
              selectedUser && selectedUser.id === user.id ? "true" : undefined
            }
            outgoing={
              selectedUser && selectedUser.id === user.id ? "true" : "false"
            }
          >
            
            <div
              className={`chatOnlineBadge ${
                onlineUsers[0]?.online && onlineUsers[0]?.userId === user.id
                  ? "online"
                  : "offline"
              }`}
            ></div>
            <UserAvatar
              src={
                user.profilePicture
                  ? PF + user.profilePicture
                  : PF + "/noAvatar.png"
              }
              alt="User Avatar"
            />
            <Username>{user.username}</Username>

            {latestMessage && selectedUser && selectedUser.id === user.id && (
              <div key={latestMessage.id}>
                <LastMessage>: {latestMessage.message?.content}</LastMessage>
                <Emoticon /> {/* Use the Emoticon styled component here */}
              </div>
            )}
          </UserListItem>
        ))}
      </ListGroup>
    );
  };
  const handleUserClick = (user) => {
    setSelectedUser(user);
    setError("");
  };
  console.log(messages.length);

    
  return (
    <div>
      {" "}
       <Topbar messages={messages} users={users} /> 
      <MessengerContainer>
        <UserListContainer>
          <ListGroup.Item>
            <div>
              <h6>UserList </h6>
              <UserList users={users} onlineUsers={onlineUsers} />
            </div>
            <h6>User Online</h6>

            <h6>{user.username}</h6>
            {user.username === selectedUser?.username && (
              <Badge variant={onlineUsers[0]?.online ? "success" : "danger"}>
                {onlineUsers[0]?.online ? "Online1" : "Offline1"}
              </Badge>
            )}
          </ListGroup.Item>
        </UserListContainer>
        <MessageSectionContainer>
          {selectedUser ? (
            <>
              <MessageHeader>
                <MessageAvatar
                  src={
                    selectedUser.profilePicture
                      ? PF + selectedUser.profilePicture
                      : PF + "/noAvatar.png"
                  }
                  alt="Selected User Avatar"
                />
                <div>
                  <StyledSpan>
                    Chat with {selectedUser.username}

                    {latestMessage && (
                      <div key={latestMessage.id}>
                        <StyledSpan>
                          {latestMessage.username}:{" "}
                          {latestMessage.message?.content}
                        </StyledSpan>
                      </div>
                    )}
                  </StyledSpan>

                  {user.username === selectedUser?.username &&
                  onlineUsers[0]?.online ? (
                    <OnlineStatus>Status Online</OnlineStatus>
                  ) : (
                    <OfflineStatus>Status Offline</OfflineStatus>
                  )}
                </div>
              </MessageHeader>
              <MessageBody>
                {messages.length > 0 ? (
                  messages.map((message) => (
                    <Message
                      key={message.id}
                      outgoing={
                        message.from === user.username ? "true" : "false"
                      }
                    >
                      <MessageContent>{message.content}</MessageContent>
                      <MessageTime>{message.createdAt}</MessageTime>
                    </Message>
                  ))
                ) : (
                  <EmptyMessage>No messages yet</EmptyMessage>
                )}
                <div
                      // className={dark ? "scrolltobottomdark" : "scrolltobottom"}
                    >
                      {/* <Button title="scroll to bottom" onClick={scrollToBottom}>
                        <KeyboardArrowDownIcon
                          // className={dark ? "scrollicondark" : "scrollicon"}
                          style={{ width: "20px", height: "40px" }}
                        />
                      </Button> */}
                    </div>
                <div ref={scrollRef}></div>

              </MessageBody>

              <MessageFooter>

<div>

  
      
                <Form  onSubmit={submitMessage}>

                  {error && <Alert variant="danger">{error}</Alert>}
                  {showKeyboard && (
                            <span
                              // className={`${
                              //   dark ? "KeyboardPicker_dark" : "KeyboardPicker"
                              // }`}
                            >
                              {/* <input
                                                                onChange={onChangeInput}
                                                            /> */}
                              <Keyboard
                                keyboardRef={(r) => (keyboard.current = r)}
                                layoutName={layout}
                                onChange={onChange}
                                onKeyPress={onKeyPress}
                              />
                            </span>
                          )}
                  <FormGroup>
                  <button
                            // className={`addfiles ${dark ? "darkButton" : ""}`}
                          >
                            <i className="fa fa-plus"></i>
                          </button>
          <InsertEmoticonIcon onClick={toggleEmojiPicker}/>
          <button className="KeyboardToggle">
                            <i
                              className="fa fa-keyboard-o"
                              onClick={keyboardToggle}
                            ></i>
                          </button>
                       
                          {/* <input
                            type="text"
                            onChange={(e) => {
                              setContent(e.target.value);
                              setStatus(false);
                            }}
                          /> */}
                          {/* <CopyToClipboard
                            text={value}
                            onCopy={() => setStatus(true)}
                          >
                            <button className="copy">
                              {" "}
                              <i className="fa fa-copy"></i>
                            </button>
                          </CopyToClipboard> */}
                          {status && (
                            <p className="copied_status"> Copied!!!</p>
                          )}    
                    <Form.Control
                      as="textarea"
                      className="rounded-pill"
                      rows={1}
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                      
                    />
                                              <div className="speak">
                            <button onClick={Speechtoinput}>
                              <i className="fa fa-microphone"></i>
                            </button>
                            {showAlert && (
                              <span className="Speaknow_alert">iWA DWI </span>
                            )}
                          </div>
                              <Geolocation > GEOX </Geolocation> 
                  
                  <MessageButton variant="primary" type="submit">
                  <i className="fa fa-paper-plane"></i>   
                  </MessageButton>
                 
                  </FormGroup>

                </Form>
              {/* <Picker data={data} onEmojiSelect={console.log} /> */}

{/* Add the emoji picker */}
 {/* {showEmojiPicker && (
  // <Picker
  //   data={data}
  //   set="facebook"
  //   onEmojiSelect={handleEmojiSelect}
  //   showPreview={false}
  //   showSkinTones={false}
  //   style={{
  //     position: 'absolute',
  //     bottom: '100px', // Adjust the position as needed
  //     right: '20px', // Adjust the position as needed
  //   }}
  // />
  <Picker data={data} onEmojiSelect={handleEmojiSelect}
  showPreview={false}
  showSkinTones={false} 
      style={{
      position: 'absolute',
      bottom: '100px', // Adjust the position as needed
      right: '20px', // Adjust the position as needed
    }}
  /> 

)} */}

</div>

              </MessageFooter>
            </>
          ) : (
            <EmptyMessage ref={scrollRef}>
              Select a user to start messaging
            </EmptyMessage>
          )}
        </MessageSectionContainer>
      </MessengerContainer>

    </div>
  );
};


// keys generator:- every new call to this function will give numbs like 0,1,2,3....
const genKey = (function () {
  var keyCode = 0;
  return function incKey() {
    return keyCode++;
  };
})();
export default Messenger;

