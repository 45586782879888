
import React, { useState } from "react";
import styles from "./Login.module.css";
import axios from "axios";
import { baseUrl } from "./../../App";
import { useAuthDispatch } from "./../../context/auth";
// import { useNavigate } from "react-router-dom";
import { useNavigate  } from "react-router-dom";
const Login = (props) => {
  // const navigate = useNavigate();
  const navigate  = useNavigate ();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useAuthDispatch();
  const token = localStorage.getItem("token") || null;
  const headers = new Headers({
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  });
  const submitLoginForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const { data } = await axios.post(
        `${baseUrl}/api/login`,
        {
          username,
          password,
        },
        { headers: headers }
      );
          // Log the token received from the server
    console.log("Token from server:", data.token);
      // Log the user object received from the server
      console.log("User object from server:", data.user);
      window.location.reload();
      navigate("/");
      if (data && data.user && data.token) {
        // localStorage.setItem("user", JSON.stringify(data.user));
        // localStorage.setItem("token", data.token);
         // Log the token before storing it
      console.log("Token before storage:", data.token);

      localStorage.setItem("user", JSON.stringify(data.user));
      localStorage.setItem("token", JSON.stringify(data.token));
      dispatch({ type: "LOGIN", payload: data });
        dispatch({ type: "LOGIN", payload: data });
              // Redirect to the home page
      navigate("/");

      // Reload the page (optional)
      window.location.reload();
      }
      else {
        // Handle the case where the response data does not contain user and token
        setError("Invalid response data");
      }
    } catch (err) {
  // Handle errors from the Axios request
  setError(err.response?.data?.message || "An error occurred");
    }

    setLoading(false);
  };

  return (
    <div className={styles.container}>
      <h2>ChatBubble</h2>
      {/* <form onSubmit={submitLoginForm}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Se connecter </button>
      </form> */}
      <form className="loginBox" onSubmit={submitLoginForm}>
        <input
          className="loginInput"
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          className="loginInput"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="loginButton" type="submit" >
          Log In

        </button>
      </form>
      <div className={styles.logininfo}>

        {/* <br /> */}
        <small>
          Don't have an account ?
        </small>
        <button
          className="loginRegisterButton"
          onClick={() => {
            navigate("/register");
          }}
        >Register </button>

        <p>Téléchargez l'application.</p>
      </div>
    </div>
  );
};

export default Login;