
// // import React, { createContext, useContext, useReducer, useEffect } from "react";
// // import jwtDecode from "jwt-decode";
// // import Cookies from "js-cookie";

// // const AuthStateContext = createContext();
// // const AuthDispatchContext = createContext();

// // const getToken = () => Cookies.get("token");

// // const setToken = (token) => {
// //   Cookies.set("token", token, { expires: 10 * 365 * 24 * 60 * 60 * 1000 });
// // };

// // const removeToken = () => {
// //   Cookies.remove("token");
// // };

// // let user;
// // const token = getToken();
// // if (token) {
// //   const decodedToken = jwtDecode(token);
// //   const expiresAt = new Date(decodedToken.exp * 10 * 365 * 24 * 60 * 60 * 1000);

// //   if (new Date() > expiresAt) {
// //     removeToken();
// //   } else {
// //     user = decodedToken;
// //   }
// // } else {
// //   console.log("No token found");
// // }

// // const authReducer = (state, action) => {
// //   switch (action.type) {

// //     case "LOGIN":
// //       localStorage.setItem("token", action.payload.token);
// //       setToken(action.payload.token);

// //       return {
// //         ...state,
// //         user: action.payload,
// //       };
// //     case "LOGOUT":
// //       removeToken();
// //       localStorage.clear();
// //       return {
// //         ...state,
// //         user: null,
// //       };
// //     default:
// //       return state;
// //   }
// // };

// // export const AuthProvider = ({ children }) => {
// //   const [state, dispatch] = useReducer(authReducer, { user });

// //   useEffect(() => {
// //     const token = getToken();
// //     if (token) {
// //       const decodedToken = jwtDecode(token);
// //       const expiresAt = new Date(decodedToken.exp * 1000);

// //       if (new Date() > expiresAt) {
// //         removeToken();
// //         dispatch({ type: "LOGOUT" });
// //       } else {
// //         dispatch({
// //           type: "LOGIN",
// //           payload: {
// //             token,
// //             ...decodedToken,
// //           },
// //         });
// //       }
// //     }
// //   }, []);

// //   return (
// //     <AuthDispatchContext.Provider value={dispatch}>
// //       <AuthStateContext.Provider value={state}>
// //         {children}
// //       </AuthStateContext.Provider>
// //     </AuthDispatchContext.Provider>
// //   );
// // };

// // export const useAuthState = () => useContext(AuthStateContext);
// // export const useAuthDispatch = () => useContext(AuthDispatchContext);
// import React, { createContext, useContext, useReducer, useEffect } from "react";
// import jwtDecode from "jwt-decode";
// import Cookies from "js-cookie";

// const AuthStateContext = createContext();
// const AuthDispatchContext = createContext();

// const getToken = () => Cookies.get("token");

// const setToken = (token) => {
//   Cookies.set("token", token, {
//     expires: 10 * 365 * 24 * 60 * 60 * 1000,
//     path: "/",
//     sameSite: "None",
//     // secure: true, // Only set this if your app runs on HTTPS
//   });
  

// };

// const removeToken = () => {
//   Cookies.remove("token");
// };

// let user;
// const token = getToken();
// if (token) {
//   const decodedToken = jwtDecode(token);
//   const expiresAt = new Date(decodedToken.exp * 1000);

//   if (new Date() > expiresAt) {
//     removeToken();
//   } else {
//     user = decodedToken;
//   console.log(decodedToken);

//   }
// } else {
//   console.log(token);
// }

// const authReducer = (state, action) => {
//   switch (action.type) {
//     case "LOGIN_START":
//       return {
//         user: null,
//         isFetching: true,
//         error: false,
//       };
//     case "LOGIN":
//       localStorage.setItem("token", JSON.stringify(action.payload.token));
//       setToken(action.payload.token);

//       return {
//         ...state,
//         user: action.payload,
//         isFetching: false,
//       };
      
//     case "LOGOUT":
//       removeToken();
//       localStorage.clear();
//       return {
//         ...state,
//         user: null,
//         isFetching: false,
//       };
      
//     default:
//       return state,console.log(state);
      
//   }
// };

// export const AuthProvider = ({ children }) => {
//   const [state, dispatch] = useReducer(authReducer, { user });

//   useEffect(() => {
//     const token = getToken();
//     if (token) {
//       const decodedToken = jwtDecode(token);
//       const expiresAt = new Date(decodedToken.exp * 1000);

//       if (new Date() > expiresAt) {
//         removeToken();
//         dispatch({ type: "LOGOUT" });
//         window.location.href = "/"; // Redirect to home page
//       } else {
//         dispatch({
//           type: "LOGIN",
//           payload: {
//             token,
//             ...decodedToken,
//           },
//         });
//       }
//     } else {
//       dispatch({ type: "LOGOUT" });
//       // window.location.href = "/"; // Redirect to home page
//     }
//   }, []);

//   return (
//     <AuthDispatchContext.Provider value={dispatch}>
//       <AuthStateContext.Provider value={state}>
//         {children}
//       </AuthStateContext.Provider>
//     </AuthDispatchContext.Provider>
//   );
// };

// export const useAuthState = () => useContext(AuthStateContext);
// export const useAuthDispatch = () => useContext(AuthDispatchContext);
import React, { createContext, useContext, useReducer, useEffect } from "react";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

const getToken = () => Cookies.get("token");

const setToken = (token) => {
  Cookies.set("token", token, {
    expires: 10 * 365 * 24 * 60 * 60 * 1000,
    // path: "/",
    // sameSite: "None",
    // secure: true, // Only set this if your app runs on HTTPS
  });
};

const removeToken = () => {
  Cookies.remove("token");
};

export const getUserFromToken = () => {
  const token = getToken();
  if (token) {
    const decodedToken = jwtDecode(token);
    const expiresAt = new Date(decodedToken.exp * 1000);

    if (new Date() > expiresAt) {
       removeToken();
    } else {
      return decodedToken;
    }
  }
  return null;
};

const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_START":
      return {
        ...state,
        user: null,
        isFetching: true,
        error: false,
      };
    case "LOGIN":
      localStorage.setItem("token", JSON.stringify(action.payload.token));
      setToken(action.payload.token);

      return {
        ...state,
        user: action.payload,
        isFetching: false,
      };
    case "LOGOUT":
       removeToken();
      localStorage.clear();
      return {
        ...state,
        user: null,
        isFetching: false,
      };
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, { user: null, isFetching: false, error: false });

  useEffect(() => {
    const token = getToken();
    if (token) {
      const decodedToken = jwtDecode(token);
      const expiresAt = new Date(decodedToken.exp * 1000);

      if (new Date() > expiresAt) {
         removeToken();
        dispatch({ type: "LOGOUT" });
        window.location.href = "/"; // Redirect to home page
      } else {
        dispatch({
          type: "LOGIN",
          payload: {
            token,
            ...decodedToken,
          },
        });
      }
    } else {
      dispatch({ type: "LOGOUT" });
      removeToken();
      // window.location.href = "/"; // Redirect to home page
    }
  }, []);

  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={state}>
        {children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
};

export const useAuthState = () => {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within an AuthProvider");
  }
  return context;
};

export const useAuthDispatch = () => {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within an AuthProvider");
  }
  return context;
};
