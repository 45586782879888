import React, { useEffect } from "react";
import { AuthProvider, useAuthDispatch, getUserFromToken } from "./context/auth";

import { BrowserRouter as Router } from "react-router-dom";
// import logo from "./logo.jpg";
import "./App.css";
// import { Button } from "reactstrap";
// import Header from "./components/Header";
// import MessageProvider from './context/message'; // Import MessageProvider
import AppRoutes from "./AppRoutes"; // Import the AppRoutes component
import Cookies from "js-cookie";
// ... code ...
const nodeEnv = process.env.NODE_ENV;
let ioUrl;

if (nodeEnv === "production") {
  ioUrl = process.env.REACT_APP_IO_URL || "https://sds-2a76.onrender.com";
} else {
  ioUrl = process.env.REACT_APP_IO_URL || "http://localhost:4000";
}

export const baseUrl = ioUrl;
function App() {
  const authDispatch = useAuthDispatch();

  useEffect(() => {
    const user = getUserFromToken();

    if (user) {
      authDispatch({
        type: "LOGIN",
        payload: {
          user,
          token: Cookies.get("token"),
        },
      });
    }
  }, [authDispatch]);

  return (
    <Router basename="/">
      <AppRoutes /> {/* Use the AppRoutes component */}
    </Router>
  );
}

export default function WrappedApp() {
  return (
      <AuthProvider>
        <App />
      </AuthProvider>
    
  );
}

