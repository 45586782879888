import React, { useEffect } from "react";
import "./profile.css";

import EditIcon from "@material-ui/icons/Edit";
export default function UpdateProfilePhoto({ submit, register, title }) {
  const [image, setImage] = React.useState("");
  const [previewUrl, setPreviewUrl] = React.useState("");
  function imageHandler(e) {
    if (e.target.files) {
      const pickedImage = e.target.files[0];
      setImage(pickedImage);
      console.log("PREVIEW", pickedImage);
    } else {
    }
  }
  useEffect(() => {
    if (image) {
      const imageReader = new FileReader();
      imageReader.onload = () => {
        setPreviewUrl(imageReader.result);
      };
      imageReader.readAsDataURL(image);
    } else {
      return;
    }
  }, [image]);
  return (
    <form className="info-text" encType="multipart/form-data" onSubmit={submit}>
      <div className="form-group">
        <label className="info-text" htmlFor="user-pic">
          {title || "Modifier"}
          <EditIcon htmlColor="tomato" className="shareIcon shareOption" />
        </label>
        <input
          type="file"
          id="user-pic"
          style={{ display: "none" }}
          name="image"
          accept=".jpeg, .png, .jpg, .gif"
          onChange={imageHandler}
          ref={register}
        />
        {previewUrl && (
          <div className="profile-image-div ">
            <img src={previewUrl} alt="" />
          </div>
        )}
      </div>
      <button type="submit">Update</button>
    </form>
  );
}
